export const MORE_COOL_STUFF = 'More cool stuff';
export const GET_DPD_APP = 'Get The DPD App';
export const REAL_TIME_DELIVERY_UPDATE = 'Real time delivery update';
export const TRACK_ANOTHER_PARCEL = 'Track Another Parcel';
export const BACK_TO_DPD_SITE = 'Back to DPD site';
export const PARCEL_INFO = 'Parcel info';
export const CHANGE_DELIVERY = 'Change this delivery';
export const WHAT_HAPPENS_NEXT_PAGE_HEADER = 'What happens next?';
export const SHOW_MAP = 'Show map';
export const BACK_TO_DETAILS = 'Back to details';
export const DELIVERY_DETAILS = 'Delivery details';
export const CLEAN_GREEN_DELIVERY = 'Clean, Green Delivery';
export const VIEW_TRACKING = 'View Tracking';
export const LEARN_ABOUT_YOUR_DRIVER = 'Learn about your driver';
export const LEARN_MORE_ABOUT_YOUR_DRIVER = 'Learn more about your driver';
export const PRINT_PICKUP_PASS = 'Print pickup pass';
export const PICKUP_PASS_HIGH_VALUE_WARNING =
  'We need to see both items so we can handover your parcel';
export const PICKUP_PASS_LOW_VALUE_INFO =
  'Your Pickup Pass will be available here, once your parcel has arrived at the Pickup Point';
export const PICKUP_PARCEL_WILL_BE_AVAILABLE =
  '* Your parcel will be available for collection for 7 days from the day of delivery';
export const DELIVERY_OPTIONS = 'Your delivery options';
export const DO_SOMETHING_DIFFERENT = 'Do something different';

export const DELIVER_TO_NEIGHBOUR = 'Deliver to my neighbour';
export const CHANGE_DELIVERY_DAY = 'Change the delivery day';
export const PRECISE = 'Precise';
export const COLLECT_FROM_DEPOT = 'Collect from Depot';
export const DELIVER_TO_SAFE_PLACE = 'Deliver to a safe place';
export const SELECT_ALTERNATE_ADDRESS = 'Select an alternate address';
export const KEEP_ME_INFORMED = 'Keep me informed';
export const VIP_MY_PARCEL = 'VIP My Parcel';
export const DUTY_PAYMENT = 'Duty Payment';
export const COLLECT_FROM_PICKUP = 'Collect from a DPD Pickup Point';
export const SAFE_PLACE_PLACEHOLDER = 'e.g. side alley access to garden etc.';
export const ACCEPT_FULL_RESPONSIBILITY =
  '* I accept full responsibility for parcels that are left in a safe place';
export const REFERENCE_ENTRY_FORM_HEADER =
  'Enter the reference number and delivery postcode';
export const REFERENCE_ENTRY_FORM_NUMBER_PLACEHOLDER =
  'Tracking Number, Calling card number';
export const REFERENCE_ENTRY_FORM_POSTCODE_PLACEHOLDER = 'Postcode';
export const TRACKING_POSTCODE_HEADER =
  'If your parcel is being delivered in the UK, see more and view your delivery options below by entering your postcode';
export const SUBMIT = 'Submit';
export const OK = 'Ok';
export const PARCEL_PAID_MESSAGE = 'This parcel has already been paid for';

export const WHAT_HAPPENS_NEXT_HEADER =
  'Here’s a summary of what your driver will do:';
export const IF_NOT_IN = 'If you’re not in';
export const LEAVE_IN_SAFE_PLACE = 'We can leave your parcel in a safe place';
export const CHOOSE_SAFE_PLACE = 'CHOOSE A SAFE PLACE';
export const TRY_NEIGHBOUR = 'We’ll automatically try a neighbour';
export const CHOOSE_NEIGHBOUR = 'CHOOSE A PREFERRED NEIGHBOUR';
export const TRY_DELIVER_ONCE = 'We’ll try to deliver and then';
export const TRY_DELIVER_TWICE = 'We’ll try to deliver twice and then';
export const VIEW_DELIVERY_DETAILS = 'VIEW DELIVERY DETAILS';
export const CHANGE = 'CHANGE';

export const NO_SAFE_PLACE = 'No safe place specified';
export const NO_NEIGHBOUR = 'No neighbour specified';
export const NO_NEIGHBOURS_FOUND =
  'Unfortunately we aren’t able to locate any neighbours at your postcode';

export const MILES = 'miles';
export const SELECT_PICKUP_SHOP_TEXT =
  'Please select a DPD Pickup Point to collect your {{customerName}} parcel from.';
export const YOUR_PARCEL_WILL_BE_AVAILABLE_TEXT =
  'Your parcel will be available';
export const PARCEL_AVAILABLE_MODAL =
  'Your parcel will be delivered to {{pickupShopName}} DPD Pickup {{pickupShopKind}}';
export const PARCEL_COLLECTED_BEFORE_TEXT =
  'Please note that your parcel must be collected before or on {{date}}';
export const SEE_OPENING_HOURS = 'SEE OPENING HOURS';
export const YES = 'YES';
export const DECLINE = 'NO, CANCEL THIS CHANGE';
export const YOUR_PICKUP_SHOP = 'YOUR PICKUP SHOP';
export const YOUR_PICKUP_$ = 'YOUR DPD PICKUP {{kind}}';
export const CONFIRM = 'SELECT';
export const PICKUP_COMPLETE_WITHOUT_TIME =
  'As requested, your parcel will now be ready for collection from your DPD Pickup {{kind}} on {{date}}';
export const NO_SHOPS =
  'Unfortunately we aren’t able to locate any Pickup Points in your area';
export const COPY = 'COPY ADDRESS';

export const ID_MESSAGE = 'Remember, our driver will need to see photo ID!';

export const RATE_DELIVERY = 'Rate your delivery';
export const POSITIVE_DELIVERY = 'Cool. Please tell us more';
export const NEGATIVE_DELIVERY = 'Oh no! Please tell us more';
export const ON_TIME = 'Delivered on time';
export const INFORMED = 'Kept me informed';
export const FOLLOWED = 'Followed my instructions';
export const NOT_RECEIVED = 'I haven’t got my parcel';
export const NOT_GREAT = 'I have my parcel but the experience wasn’t great';
export const COMPLAINT = 'I have a complaint about this delivery';
export const WRONG_PLACE = 'Parcel delivered to the wrong place';
export const RATE_COMPLETE_SORRY = 'We’re sorry to hear that';
export const RATE_COMPLETE_INVESTIGATE =
  'If you’d like us to investigate this, please tell us more';
export const CONTACT_NAME = 'Contact Name';
export const ADDRESS = 'Address';
export const CONTACT_NUMBER = 'Contact number';
export const EMAIL = 'Email Address';
export const INVESTIGATE_DESCRIPTION = 'Please tell us more about the problem';
export const HOME = 'HOME';
export const THANKS = 'Thanks';
export const THANKS_POSITIVE = 'Thanks, we’re happy you’re happy';
export const DEPOT_MANAGER_FEEDBACK =
  'This feedback will passed to your local \n depot manager';
export const PLEASE_CALL_US =
  'If you’d like to talk to us about this delivery,\n please call';
export const SORRY = 'We’re sorry to hear that ';
export const DRIVER_FEEDBACK_POSITIVE = 'We’ll pass this on to {{driverName}}';
export const DRIVER_FEEDBACK_NEGATIVE =
  'Your feedback will be passed on to the depot manager who will share this with {{driverName}}';
export const SKIP = 'Skip';
export const SKIP_COMPLIMENT = 'Skip compliment';
export const DRIVER_FEEDBACK_QUESTION =
  'Would you like to leave some feedback for your driver {{driverName}}?';
export const DRIVER_RATING_POSITIVE = 'Thank you for the rating';
export const DRIVER_DID_BEST =
  'You can also choose which of these {{driverName}} did best';
export const DRIVER_RATING_NEGATIVE = 'Oh no, what went wrong?';
export const SEND = 'Send';
export const SOMETHING_ELSE = 'Something else';

export const DAY_OF_DELIVERY = 'Day of delivery';
export const SELECT_UPCOMING_DAY =
  'Select an upcoming day when you would like your parcel to be delivered';
export const SELECT_NEW_COLLECTION_DAY =
  'Select a new day and hour time slot when\n you would like to collect your parcel';
export const SELECT_NEW_DELIVERY_DAY =
  'Select a new day and time slot when\n you would like your parcel to be delivered';
export const SELECT_DAY_AND_HOUR_TIME =
  'Select a new day and hour time slot when\n you would like your parcel to be delivered';
export const AFTER_AND_BEFORE_NOON_$ =
  'After 12 noon, 7 days a week only £{0}\nBefore 12 noon, 7 days a week only £{1}';
export const PICK_YOUR_SLOT_$ =
  'Pick your preferred delivery slot from as little as £{0}, 7 days a week';
export const GIVEN_THE_VIP = 'Your parcel has been given the VIP experience.';
export const PUSH_ALLOW_CONFIRM =
  'Clicking ‘Allow’ on the next screen will allow real time updates on all of your deliveries';
export const PUSH_DISALLOW_CONFIRM =
  'Clicking on ‘Yes, opt out’ will remove real time delivery updates from your browser';
export const BROWSER_NOT_SUPPORTED =
  'This browser is not supported for this feature';
export const OTHER_SAFE_PLACE = 'Other safe place';
export const WHERE_HAS_MY_PARCEL_BEEN = 'Where has my parcel been?';
export const ADDITIONAL_INFO = 'Additional information';
export const PARCEL_DRIVER_ARRIVE_TIME_$ =
  'We’ll be with you in approximately {0}';

// CONSTANTS FOR WHN V1
export const WE_WILL = 'We will';
export const IF_NOT_WE_WILL = 'If you’re not in, we will';
export const TRY_ABOVE = 'We’ll try the above and then';
export const DELIVER_TO_ = 'Deliver to:';
export const DELIVER_TO_$ = 'Deliver to {0}:';
export const SAFE_PLACE = 'Safe Place';
export const NEIGHBOUR = 'Neighbour';
export const DELIVER_TO_NEAREST_PICKUP =
  'Deliver to your nearest DPD Pickup Point\nfor you to collect';
export const PICKUP_SHOP = 'Pickup Shop';
export const NO_DELIVERY_ATTEMPTS = 'There are no attempts possible';
export const CHOOSE = 'CHOOSE';

export const LET_YOU_KNOW_TIME_MESSAGE =
  "We'll let you know when your parcel is ready to collect";

export const HOW_TO_PROCEED = 'Tell us how to proceed';
export const YOUR_OPTIONS = 'Tell us how to proceed. Your options are:';
export const IMPORT_CHARGES_CALCULATED =
  'See a breakdown of these import charges';
export const BREAKDOWN_OF_CONTENTS =
  'See a breakdown of the contents of your shipment';
export const ITEM_DESCRIPTION = 'Item Description';
export const QUANTITY = 'Quantity';
export const DUTY_TO_PAY = 'Duty to pay:';
export const TAX_TO_PAY = 'Tax to pay:';
export const CLEARANCE_FEE = 'Clearance Fee:';
export const VAT_ON_CLEARANCE_FEE = 'VAT on Clearance Fee:';
export const TOTAL = 'Total:';
export const CHOOSE_WAY_TO_PAY = 'Choose a way to pay';
export const EMAIL_FOR_VAT_INVOICE =
  'For a VAT invoice, please provide your email address';
export const DUTY_PAYMENT_TERMS_CONDITIONS =
  'By ticking this box, I appoint DPDgroup UK Ltd. EORI: GB754532232000 to carry out customs formalities for me in relation to the goods and to act on my behalf in the capacity of Direct Customs Agent in accordance with section 21 of the Taxation (Cross Border Trade) Act 2018 and in accordance with DPDgroup UK Ltd Terms & Conditions. I understand that I will be responsible for payment of all taxes and duties in relation to the goods. DPDgroup UK Ltd will rely on the information provided by the sender of the goods in relation to the description of the goods and their value, but you must tell us immediately if there is any error in this information.';
export const DUTY_PAYMENT_TERMS_CONDITIONS_HINT =
  'I understand that if I do not appoint you as my Direct Customs Agent the goods will be returned to the sender.';
export const FUTURE_IMPORTS_MESSAGE =
  'If you would like DPD to use a deferment account for future imports then please';
export const CONTACT_US = 'contact us';
export const THANKS_FOR_PAYING =
  'Thanks for paying the import charges, you will receive a confirmation email shortly';
export const DUTY_NOTIFICATION_MESSAGE =
  'We’ll send the recipient an SMS or email as soon as we have a confirmed date for their delivery';
export const RETURN_PARCEL_CONFIRMATION =
  'Are you sure you want the parcel returned to the sender?';
export const RETURN_PARCEL_BUTTON = 'Yes, return the parcel';
export const I_WANT_MY_PARCEL = 'Actually, I want my parcel';
export const NOT_PAID_MESSAGE =
  'As you have chosen not to pay your import charges, your parcel will be returned to the sender';
export const NO_DELIVERY_OPTIONS =
  'There are no delivery options\navailable for this parcel';
export const PAYMENT_PROCESSING =
  'Your payment is still processing, please try again later';

export const CHECK_YOUR_AIR = 'Check the air quality in your postcode';

// like dislike 2
export const SORRY_TO_HEAR_MESSAGE = 'We are really sorry to hear this.';
export const FRIENDLY_CALL_MESSAGE =
  'Would you like one of our friendly team to give you a <b>call</b> and discuss this further?';
export const NO_THANKS = 'NO THANKS';
export const YES_PLEASE_CALL_ME = 'YES, PLEASE CALL ME';
export const GREAT_CHOOSE_A_DAY =
  'Great, please choose a day that would be best for us to call you.';
export const CHANGE_DATE = 'CHANGE DATE';
export const CHOOSE_SUITABLE_TIME_SLOT = 'CHOOSE A SUITABLE TIME SLOT';
export const TEAM_WILL_CALL =
  'Great, one of our team will call you between {0} on {1}';
export const CHANGE_SLOT = 'CHANGE SLOT';
export const BEST_NUMBER_MESSAGE =
  'Please let us know the best number to reach you on';
export const CONTACT_NUMBER_PLACEHOLDER = 'Contact Number';
export const CHAT_SOON = "We'll chat to you soon";
export const DEPOT_MANAGER =
  'Your feedback will be passed on to the depot manager';
export const CHOOSE_TIME_SLOT = 'Choose a time slot';
export const INCORRECT_PARCEL_DETAILS = 'The details entered are incorrect';

export const ADD_PASS_TO_YOUR_WALLET = 'ADD PASS TO YOUR WALLET';
