import React, { useCallback } from 'react';
import { makeStyles, withStyles } from '@material-ui/styles';
import {
  ExpansionPanel as MuiExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary as MuiExpansionPanelSummary,
  Grid,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {
  IMPORT_CHARGES_CALCULATED,
  DUTY_TO_PAY,
  TAX_TO_PAY,
  CLEARANCE_FEE,
  VAT_ON_CLEARANCE_FEE,
  TOTAL,
} from '../../../../../constants/message';
import DutyDetailsItem from './DutyDetailsItem';
import { prepareTaxValue } from '../utils';

const ExpansionPanel = withStyles({
  root: {
    boxShadow: 'none',
    background: 'transparent',
    border: 'none',
    marginTop: '16px',
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 0,
      marginTop: '16px',
    },
    '@media (max-width: 600px)': {
      paddingLeft: '0.625rem',
      paddingRight: '0.625rem',
    },
  },
  expanded: {
    margin: 0,
    marginTop: '1.875rem',
  },
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    padding: 0,
    minHeight: 'unset',
    flexWrap: 'nowrap',
    '&$expanded': {
      minHeight: 'unset',
    },
  },
  content: {
    margin: 0,
    '&$expanded': {
      margin: 0,
    },
    '@media (max-width: 380px)': {
      maxWidth: '90%',
    },
  },
  expanded: {
    margin: 0,
  },
  expandIcon: {
    padding: 0,
    marginLeft: '0.5rem',
    color: '#979797',
    '@media (max-width: 600px)': {
      marginLeft: 0,
    },
  },
})(MuiExpansionPanelSummary);

const useStyles = makeStyles(({ breakpoints, typography, palette }) => ({
  panelHeader: {
    fontSize: typography.pxToRem(20),
    color: '#1d9ad0',
    letterSpacing: typography.pxToRem(0.44),

    [breakpoints.down('xs')]: {
      fontSize: typography.pxToRem(15),
    },
  },
  dutyPanelDetails: {
    padding: 0,
  },
  dutyDetails: {
    maxWidth: typography.pxToRem(280),
    marginTop: typography.pxToRem(16),
  },
  divider: {
    height: typography.pxToRem(1),
    opacity: 0.4,
    background: '#a6a6a6',
    marginTop: typography.pxToRem(5),
  },
  totalText: {
    fontWeight: 'normal',
    lineHeight: 2.11,
  },
  totalValue: {
    lineHeight: 2.11,
    color: palette.text.redWhite,
    fontWeight: 500,
  },
}));

type Props = {
  onPanelOpen: Function,
  total: number,
  dutyToPay: number,
  taxToPay: number,
  clearanceFee: number,
  vatOnClearanceFee: number,
};

const DutyDetailsPanel = ({
  onPanelOpen,
  total,
  dutyToPay,
  taxToPay,
  clearanceFee,
  vatOnClearanceFee,
}: Props) => {
  const styles = useStyles();

  const handlePanelOpen = useCallback(() => {
    onPanelOpen(true);
  }, [onPanelOpen]);

  const handlePanelClose = useCallback(() => {
    onPanelOpen(false);
  }, [onPanelOpen]);

  return (
    <ExpansionPanel
      TransitionProps={{
        onExited: handlePanelClose,
        onEnter: handlePanelOpen,
      }}
    >
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={styles.panelHeader}>
          {IMPORT_CHARGES_CALCULATED}
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails
        classes={{
          root: styles.dutyPanelDetails,
        }}
      >
        <Grid container className={styles.dutyDetails}>
          {!!dutyToPay && (
            <DutyDetailsItem
              name={DUTY_TO_PAY}
              value={prepareTaxValue(dutyToPay)}
            />
          )}
          {!!taxToPay && (
            <DutyDetailsItem
              name={TAX_TO_PAY}
              value={prepareTaxValue(taxToPay)}
            />
          )}
          {!!clearanceFee && (
            <DutyDetailsItem
              name={CLEARANCE_FEE}
              value={prepareTaxValue(clearanceFee)}
            />
          )}
          {!!vatOnClearanceFee && (
            <DutyDetailsItem
              name={VAT_ON_CLEARANCE_FEE}
              value={prepareTaxValue(vatOnClearanceFee)}
            />
          )}
          <Grid item xs={12} className={styles.divider} />
          <DutyDetailsItem
            name={TOTAL}
            value={prepareTaxValue(total)}
            nameClassName={styles.totalText}
            valueClassName={styles.totalValue}
          />
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default DutyDetailsPanel;
